function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const SEARCH_ROOT = "/search";

export const PATH_PAGE = {
  about: "/info/about-us",
  resort: "/about-resort",
  contact: "/contact-us",
  legalInfo: "/info/legal-information",
  faq: "/info/faq",
  apply: "/info/apply",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  ownersignin: "/app/owner/profile-edit",
};

export const SEARCH = {
  root: SEARCH_ROOT,
  top: path(SEARCH_ROOT, "/top"),
};
