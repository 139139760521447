// next
// @mui
import { Box, Grid, Stack, useMediaQuery, Container, Typography, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// routes
import { PATH_PAGE } from "../../../../routes/paths";
// _mock
import { _socials } from "../../../../_mock/arrays";
import { Iconify, Logo } from "../../../components";

import footerBg from "./footer_bg.png";
import { Link } from "../../../../fe-ui/atoms/Link/Link";
import { useTranslation } from "../../../../fe-ui/hooks/use-translation";
import getConfig from "next/config";
import { fixAssetPath } from "../../../../fe-ui/utils/fixAssetPath";

// ----------------------------------------------------------------------

export default function Footer() {
  const { publicRuntimeConfig } = getConfig();

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const t = useTranslation();

  const appVersion = `, ${process.env.CI_COMMIT_TAG ?? "v" + publicRuntimeConfig.appVersion}`;

  const LINKS = [
    {
      headline: t("Footer_aboutProject"),
      children: [
        { name: t("Footer_aboutSeaya"), href: PATH_PAGE.about },
        { name: t("Footer_contacts"), href: PATH_PAGE.contact },
        { name: t("Footer_become_partner"), href: PATH_PAGE.apply },
      ],
    },
    // {
    //   headline: t("Footer_services"),
    //   children: [
    //     { name: t("Footer_restaurants"), href: "#" },
    //     { name: t("Footer_affiche"), href: "#" },
    //     { name: t("Footer_infrastructure"), href: "#" },
    //     { name: t("Footer_sport"), href: "#" }
    //   ]
    // },
    {
      headline: t("Footer_users"),
      children: [
        { name: t("footer_legal"), href: PATH_PAGE.legalInfo },
        { name: t("footer_faq"), href: PATH_PAGE.faq },
        { name: t("footer_owners_signin"), href: PATH_PAGE.ownersignin },
      ],
    },
  ];

  const COPY = t("Footer_copy");

  const mainFooter = (
    <Box
      component={"footer"}
      sx={{
        position: "relative",
        bgcolor: "background.neutral",
      }}
    >
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
          sx={{
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}
        >
          <Grid item xs={8} md={3}>
            <Logo isFooter isDark />
            <Stack
              direction={"row"}
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials
                // TODO: Social links
                .filter(() => false)
                .map((social) => (
                  <IconButton key={social.name}>
                    <Iconify icon={social.icon} />
                  </IconButton>
                ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={9} sx={{ position: "relative" }}>
            <Stack spacing={4} direction={{ xs: "column", md: "row" }}>
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2} alignItems={{ xs: "center", md: "flex-start" }}>
                  <Typography component={"div"} variant={"overline"}>
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link key={link.name} href={link.href} color={"inherit"} variant={"body2"}>
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
              {!isXS && (
                <Box
                  component={"img"}
                  draggable={false}
                  src={fixAssetPath(footerBg.src)}
                  sx={{
                    position: "absolute",
                    bottom: "-40%",
                    right: 0,
                    width: 200,
                  }}
                />
              )}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant={"caption"}
          component={"div"}
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {COPY}

          {appVersion}
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
}
