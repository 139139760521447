import dynamic from "next/dynamic";
import { Box } from "@mui/material";
import { ReactNode } from "react";

import Footer from "./Footer";
import Header from "./Header";
import { HeaderBannerListResponseDataItem } from "../../../api/content-client/service";

import tmpHeaderBanner from "./header.webp";
import { AuthProvider } from "../../../controllers/AuthController";
import { CLIENT_ID, USER_REALM } from "../../../@types/roleModel";

const CookieBanner = dynamic(
  () => import("../../../fe-ui/blocks/CookieBanner/CookieBanner").then((x) => x.CookieBanner),
  {
    ssr: false,
  }
);

type Props = {
  children?: ReactNode;
  renderHeaderContent?: () => ReactNode;
  banners?: HeaderBannerListResponseDataItem[];
  withBackgroundImage?: boolean;
  backgroundImageContent?: () => ReactNode;
  enableMobileNav?: boolean;
};

export default function MainLayout({
  children,
  withBackgroundImage,
  backgroundImageContent,
  renderHeaderContent,
  enableMobileNav = false,
}: Props) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      <AuthProvider realm={USER_REALM.owners} client_id={CLIENT_ID.owners}>
        <Header renderHeaderContent={renderHeaderContent} enableMobileNav={enableMobileNav} />
      </AuthProvider>
      {withBackgroundImage && (
        <Box
          sx={{
            backgroundColor: "#002D62",
            flexShrink: 0,
            minHeight: 600,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `url(${tmpHeaderBanner.src})`,
            pt: { xs: 8, md: 11 },
          }}
        >
          {backgroundImageContent?.()}
        </Box>
      )}

      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: withBackgroundImage ? 0 : { xs: 8, md: 11 },
        }}
      >
        {children}
      </Box>

      <Footer />

      <CookieBanner />
    </Box>
  );
}
